import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../images/logo.png';
import confetti from 'canvas-confetti';
import { motion } from 'framer-motion';
import spotifyIcon from '../images/4.png';
import telegramIcon from '../images/3.png';
import discordIcon from '../images/1.png';
import githubIcon from '../images/2.png';
import youtubeIcon from '../images/5.png';
import { isMobile } from 'react-device-detect';
import effectImage from '../images/effect.gif';
import bgGif from '../images/output.gif';
import videoSrc from '../video/p2.mp4';

const words = ['hilltty', 'хиллти', 'ヒルティ', '希爾蒂', 'zov'];

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
`;


const TypewriterContainer = styled.div`
  margin-top: 20px;
  margin-left: ${isMobile ? '1px' : '0'}; // Добавьте отступ слева для мобильных устройств
  user-select: none; 
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 24px;
  opacity: 0;
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `} 0.5s ease 0.8s forwards;
`;

const TypewriterText = styled.span`

  color: white;
  font-family: 'Consolas', monospace;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 1px;
`;

const Cursor = styled.span`
  position: absolute;
  right: -10px;
  top: calc(50% + 2px);
  transform: translateY(-50%);
  width: 2px;
  height: 24px;
  background-color: white;
  opacity: ${props => props.visible ? 1 : 0};
  animation: ${props => props.blink ? blink : 'none'} 0.35s infinite; /* Уменьшена скорость анимации */
  animation-delay: 0.8s; /* Уменьшена задержка */
`;

const IconContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-start'};
  margin-top: ${isMobile ? '-150px' : '20px'}; // Поднимаем иконки выше на мобильных устройствах
  width: 100%;
  min-height: ${isMobile ? 'calc(100vh - 300px)' : 'auto'};
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Видео находится за контентом */
  filter: blur(5px); /* Применение эффекта размытия, если нужно */

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    transform: translate(-50%, -50%) scale(1); /* Увеличение видео в 6 раз */
  }
`;

const IconWrapper = styled(motion.div)`
  width: ${props => props.isFhd ? '50px' : '75px'};
  height: ${props => props.isFhd ? '50px' : '75px'};
  margin: ${props => props.isFhd ? '0 10px 0 0' : '0 30px 0 0'};
  opacity: 0.5;
  cursor: pointer;
  user-select: none; /* Запрещает выделение иконок */
`;


const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const iconVariants = {
  hover: {
    scale: 1.1,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  }
};
const Typewriter = () => {
  const [text, setText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(false);
  const [cursorBlink, setCursorBlink] = useState(false);
  const [lastTypedTime, setLastTypedTime] = useState(Date.now());
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    const startDelay = setTimeout(() => {
      setIsStarted(true);
    }, 3600); // 3.6 секунды задержки перед началом анимации

    return () => clearTimeout(startDelay);
  }, []);

  useEffect(() => {
    const cursorDelay = setTimeout(() => {
      setCursorVisible(true);
    }, 3200); // 3.2 секунды задержки
  
    return () => clearTimeout(cursorDelay);
  }, []);

  useEffect(() => {
    if (!isStarted) return;

    const currentWord = words[wordIndex];
    
    const typeSpeed = () => Math.random() * (150 - 50) + 50;
    const errorProbability = 0.1;
    
    const timer = setTimeout(() => {
      const now = Date.now();
      if (now - lastTypedTime > 1000) {
        setCursorBlink(true);
      } else {
        setCursorBlink(false);
        setCursorVisible(true);
      }

      if (!isDeleting && text.length < currentWord.length) {
        let nextChar = currentWord[text.length];
        if (Math.random() < errorProbability) {
          // Simulate typo
          nextChar = 'abcdefghijklmnopqrstuvwxyz'[Math.floor(Math.random() * 26)];
        }
        setText(text + nextChar);
        setLastTypedTime(now);
      } else if (isDeleting && text.length > 0) {
        setText(text.slice(0, -1));
        setLastTypedTime(now);
      } else if (text.length === currentWord.length) {
        setIsDeleting(true);
        return; // Используем return вместо setTimeout
      } else if (text.length === 0) {
        setIsDeleting(false);
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        return; // Используем return вместо setTimeout
      }
    }, typeSpeed());

    return () => clearTimeout(timer);
  }, [text, isDeleting, wordIndex, lastTypedTime, isStarted]);

  return (
    <TypewriterContainer>
      <TypewriterText>{text}</TypewriterText>
      <Cursor visible={cursorVisible} blink={cursorBlink} />
    </TypewriterContainer>
  );
};


const MotionBackgroundWrapper = styled.div`

  position: absolute;
  top: 61%;
  left: 100%;
  width: 40%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;

  @media (max-width: 768px) {
    top: 92%;
    left: 80%;
    width: 50%;
  }
`;


const MotionBackgroundImage = styled(motion.img)`

  width: 100%;
  height: auto;
  object-fit: cover;
  will-change: transform, width, height, opacity;
`;


const backgroundVideoVariants = {
  initial: {
    opacity: 0,
    width: isMobile ? '80%' : '20%',
    height: isMobile ? '25%' : '50%',
    top: isMobile ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  middle: {
    opacity: 1,
    width: isMobile ? '80%' : '20%',
    height: isMobile ? '25%' : '50%',
    top: isMobile ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: {
      opacity: { duration: 4 }, // Увеличиваем продолжительность для плавного появления
      width: { duration: 2 },
      height: { duration: 2 },
      top: { duration: 2 },
      left: { duration: 2 }
    }
  },
  final: {
    opacity: 1,
    width: isMobile ? '80%' : '50%',
    height: isMobile ? '25%' : '100%',
    top: isMobile ? '80%' : '50%',
    left: isMobile ? '50%' : '70%',
    transform: 'translate(-50%, -50%)',
    transition: { 
      duration: 1,
      ease: [1, 0.1, 0.1, 1]
    }
  }
};


const Header = styled.header`
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 3rem;
  margin-bottom: 1rem;
  z-index: 2;
  opacity: 0;
  transform: translateY(-20px);
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `} 0.25s ease 0.75s forwards; /* Уменьшено время задержки */

`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const smoothAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;


const EffectOverlay = styled.img`
  position: absolute;
  max-width: 150%;
  max-height: 150%;
  object-fit: contain; /* Сохраняет пропорции изображения */
  pointer-events: none;
  margin-top: 0px;
  margin-left: 5px;
  z-index: 1;
  content: url(${effectImage});
`;

const LogoWrapper = styled.div`
  opacity: 0;
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  `} 0.25s ease 0.8s forwards; 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: ${isMobile ? '-50px' : '0'}; // Добавьте отступ слева для мобильных устройств

  &:hover img {
    transform: scale(1.1);
  }

  &:hover::after {
    opacity: 0.7;
    animation: ${smoothAnimation} 6s infinite linear;
    transform: scale(0.8);
  }

  &::after {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    width: 100px;
    height: 100px;
    filter: blur(40px);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background-size: 400% 400%;
    z-index: -1;
  }
`;


const Logo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.1s ease-in-out;
  z-index: 0;
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${isMobile ? 'center' : 'flex-start'};
  text-align: ${isMobile ? 'center' : 'left'};
  padding: 2rem;
  margin-left: ${isMobile ? '0' : '5rem'};
  margin-top: ${isMobile ? '0' : '-5rem'};
  min-height: ${isMobile ? 'calc(100vh - 250px)' : 'auto'}; // Уменьшение минимальной высоты
  padding-top: ${isMobile ? '50px' : '0'}; // Дополнительный отступ сверху для мобильных устройств
  z-index: 1;
  opacity: 0;
  animation: 
    ${keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `} 0.25s ease 0.4s forwards; /* Уменьшено время задержки */
`;


const Title = styled.h1`
  color: white;
  font-size: ${isMobile ? '2vh' : '2.5rem'}; // Использование vh для мобильных устройств
  margin-bottom: ${isMobile ? '1vh' : '1rem'};
  margin-top: ${isMobile ? '5vh' : '0'}; // Подвиньте заголовок выше на мобильных устройствах
  opacity: 0;
  animation: 
    ${keyframes`
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    `} 0.25s ease 0.5s forwards; /* Уменьшено время задержки */
  user-select: none; /* Запрещает выделение текста */
`;



const Description = styled.p`
  color: #ccc;
  max-width: ${isMobile ? '90%' : '600px'};
  margin-bottom: ${isMobile ? '1rem' : '2rem'}; // Подвиньте описание выше на мобильных устройствах
  margin-top: ${isMobile ? '1.5rem' : '0'}; // Подвиньте описание выше на мобильных устройствах
  font-size: ${isMobile ? '0.9rem' : '1rem'};
  opacity: 0;
  text-align: ${isMobile ? 'center' : 'left'};
  animation: 
    ${keyframes`
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    `} 0.25s ease 0.6s forwards; /* Уменьшено время задержки */
  user-select: none; /* Запрещает выделение текста */
`;


const BlurredHeader = styled(Header)`
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
`;


const DownloadText = styled.span`
  font-family: 'Consolas', monospace;
  font-size: 2.7rem;
  user-select: none; /* Запрещает выделение текста */
`;

function MainPage() {
  const [animationStage, setAnimationStage] = useState('initial');
  const [backgroundVisible, setBackgroundVisible] = useState(false);
  const [isFhd, setIsFhd] = useState(false);

  useEffect(() => {
    // Проверяем разрешение экрана при монтировании компонента
    const checkScreenSize = () => {
      if (window.innerHeight < 600) {
        // Redirect to min-res.html
        window.location.href = 'min-res.html';
      }
    };
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    // Проверяем разрешение экрана при монтировании компонента
    const checkScreenSize = () => {
      if (window.innerWidth < 360) {
        // Redirect to min-res.html
        window.location.href = 'min-res.html';
      }
    };
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    // Проверяем разрешение экрана при монтировании компонента
    const handleResize = () => {
      setIsFhd(window.innerWidth <= 1920); // Определяем, является ли экран FHD или 2K
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Вызываем функцию один раз при монтировании
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    // Запрещаем прокрутку на iOS
    document.body.style.position = 'fixed';
    document.body.style.top = `0`;
    document.body.style.left = `0`;
    document.body.style.right = `0`;
    document.body.style.bottom = `0`;
    
    return () => {
      // Возвращаем стандартное поведение при размонтировании компонента
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.right = '';
      document.body.style.bottom = '';
    };
  }, []);

  useEffect(() => {
    // Показываем фон после короткой задержки
    const timer = setTimeout(() => {
      setBackgroundVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const animationDuration = isMobile ? 500 : 500; // 1.8 секунды для мобильных, 3.6 для десктопов

    // Начальная анимация
    setAnimationStage('middle');

    // Переход к финальной стадии
    const timer = setTimeout(() => {
      setAnimationStage('final');
    }, animationDuration);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
    return () => {
      document.body.removeEventListener('touchmove', preventDefault);
    };
  }, []);

  const handleConfetti = () => {
    confetti({
      particleCount: 80,
      spread: 90,
      origin: { y: 0.55, x: isMobile ? 0.5 : 0.73 } // Центр экрана для мобильных устройств
    });
  };

  const handleIconClick = (url) => {
    window.open(url, "_blank");
  };

  const handleMainPageClick = () => {
    window.location.href = "https://www.7-zip.org/";  
  };

  return (
    <PageContainer>
      <VideoBackground autoPlay loop muted>
        <source src={videoSrc} type="video/mp4" />
      </VideoBackground>
    <MotionBackgroundWrapper>
      <MotionBackgroundImage
        src={bgGif}
        initial="initial"
        animate={animationStage}
        variants={backgroundVideoVariants}
      />
    </MotionBackgroundWrapper>

    <BlurredHeader>
      <Header>
        <LogoContainer>
        <LogoWrapper>
        <Logo src={logo} alt="Ангелина 200м от вас. Купит интернет - провод, купит пиво." onClick={handleMainPageClick}/>
        <EffectOverlay src={effectImage} alt="Effect" />
          </LogoWrapper>
          <Typewriter />
        </LogoContainer>
      </Header>
      </BlurredHeader>
      
      <Main>
        <Title>
        <DownloadText onClick={handleConfetti}>
          java developer
        </DownloadText>
      </Title>
      <Description isFhd={isFhd}>
        Когда ПП?
      </Description>
      <IconContainer>
        <IconWrapper
          isFhd={isFhd}
          variants={iconVariants}
          whileHover="hover"
          onClick={() => handleIconClick("https://open.spotify.com/user/073gq6uta4c5zag5ftclcr7en?si=a1d0f6e0eb2349dd")}
        >
          <Icon src={spotifyIcon} alt="Spotify" />
        </IconWrapper>
        <IconWrapper
          isFhd={isFhd}
          variants={iconVariants}
          whileHover="hover"
          onClick={() => handleIconClick("https://t.me/hilltty")}
        >
          <Icon src={telegramIcon} alt="Telegram" />
        </IconWrapper>
        <IconWrapper
          isFhd={isFhd}
          variants={iconVariants}
          whileHover="hover"
          onClick={() => handleIconClick("https://discordapp.com/users/412623325886677015")}
        >
          <Icon src={discordIcon} alt="Discord" />
        </IconWrapper>
        <IconWrapper
          isFhd={isFhd}
          variants={iconVariants}
          whileHover="hover"
          onClick={() => handleIconClick("https://github.com/hilltty")}
        >
          <Icon src={githubIcon} alt="GitHub" />
        </IconWrapper>
        <IconWrapper
          isFhd={isFhd}
          variants={iconVariants}
          whileHover="hover"
          onClick={() => handleIconClick("https://www.youtube.com/channel/UCi8RN4oFauC_MIj717ENtMQ")}
        >
          <Icon src={youtubeIcon} alt="YouTube" />
        </IconWrapper>

        </IconContainer>
      </Main>
    </PageContainer>
  );
}

export default MainPage;