import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/Home'; // убедитесь, что путь правильный

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} /> {/* Default route */}
          <Route path="/home" element={<MainPage />} />
        </Routes>
      </Router>
  );
}

export default App;
